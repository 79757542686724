import React from "react";
import {
  Route,
  PathMatch,
  Routes as Switch,
  RouteProps,
  useLocation,
} from "react-router-dom";
import { Location } from "history";

import { CART_PAGE_ROUTE } from "routes/routesNames";

import CartPage from "pages/cart";

export interface RouteType extends RouteProps {
  layout?: React.FC;
}

export interface RoutesCompProps {
  location?: Location;
  match?: PathMatch;
}

export const Routes = (routes: RouteType[]) => {
  const RoutesComponent = () => {
    const location: any = useLocation();

    const background = location.state && location.state.background;

    return (
      <>
        <Switch location={background || location}>
          {routes.map((props, i) => {
            const element = props.layout ? (
              <props.layout>{props.element}</props.layout>
            ) : (
              props.element
            );

            return <Route key={props.path} {...props} element={element} />;
          })}
        </Switch>
        <Switch>
          <Route
            path={CART_PAGE_ROUTE}
            element={<CartPage isBackground={background} />}
          />
        </Switch>
      </>
    );
  };
  return RoutesComponent;
};
