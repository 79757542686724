import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { INDEX_PAGE_ROUTE } from "routes/routesNames";

import s from "./Logo.module.sass";

export interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <Link to="/" className={clsx(s.Logo, className)}>
      Celestial
    </Link>
  );
};
