import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import s from "styles/pages/Items.module.sass";
import { ITEM_PAGE_SHOW_ROUTE } from "routes/routesNames";
import { useProducts } from "hooks/useProducts";
import Soon from "components/Soon/Soon";
import { nanoid } from "nanoid";
import { BubbleSlider } from "components/BubbleSlider/BubbleSlider";
import ProductItem from "components/ProductItem/ProductItem";

const ItemsPage = () => {
  const { pathname } = useLocation();

  const { products } = useProducts();
  console.log(products);

  const [mutatedProducts, setMutatedProducts] = useState(products);

  useEffect(() => {
    // @ts-ignore

    const newMutatedProducts = mutatedProducts && [...mutatedProducts];

    if (newMutatedProducts) {
       /* const ashTrayIndex = newMutatedProducts.findIndex(
        (p) => p.id === 500701165
      );  */
      //const [ashTray] = newMutatedProducts.splice(ashTrayIndex, 1);
      const oldLength = newMutatedProducts.length;
      newMutatedProducts.length = 10;
      newMutatedProducts.fill(undefined, oldLength);
      newMutatedProducts[7] = undefined;
      console.log(newMutatedProducts);

      setMutatedProducts(newMutatedProducts);
    }
  }, []);

  const renderBackground = () => {
    if (pathname === "/items/0") {
      return s.Background;
    }
    if (pathname === "/items/1") {
      return s.BackgroundPurg;
    }
    if (pathname === "/items/2") {
      return s.BackgroundPar;
    }
  };

  const renderSoonItems = () => {
    const soonItems = [
      13423, 24234, 3324, 4234, 54423, 64234, 74234, 84234, 922344, 76868,
      98796,
    ];

    let soonLength;

    if (pathname === "/items/1") {
      soonLength = 11;
    }
    if (pathname === "/items/2") {
      soonLength = 10;
    }
    // @ts-ignore
    soonItems.length = soonLength;
    return soonItems.map((s) => <Soon key={s} />);
  };

  return (
    <div className={s.Wrapper}>
      <div className={s.Container}>
        <div className={s.Items}>
          {pathname === "/items/0"
            ? mutatedProducts &&
              mutatedProducts.map((p, ind) => {
                if (p && ind !== 7) {
                  return (
                    <ProductItem id={p.id} name={p.name} image={p.images[0]} />
                  );
                } else if (p && ind === 7) {
                  return <BubbleSlider products={p} />;
                } else {
                  return <Soon />;
                }
              })
            : renderSoonItems()}
        </div>
      </div>
      <div>
        <div className={renderBackground()} />
      </div>
    </div>
  );
};
export default ItemsPage;
