import React, { useEffect, useState } from "react";

import "styles/app.sass";
import "styles/ecwid.scss";

import { AppRoutes } from "routes";
import CartProvider from "hooks/useCart";
import ProductsProvider from "hooks/useProducts";
import { BarLoader } from "react-spinners";

const images = [
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563080/celestial/cloudsBack1_nvfcxo.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563077/celestial/clouds_mobile_top_hzhtcs.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563078/celestial/moon_rjkrrz.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563076/celestial/arrow-top_q31ew8.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563076/celestial/arrow-bottom_ebwsly.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563081/celestial/light_deu4po.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563080/celestial/raysall_mqhhz2.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563077/celestial/center_brhspn.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563080/celestial/shine_bo3r0n.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563079/celestial/cloudsFront2_bp51u3.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662563077/celestial/clouds_mobile_bottom_vleeil.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1662562954/celestial/background_nipskr.jpg",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80//v1662563076/celestial/bg_favvpj.jpg",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664731609/celestial/inferno-gate_ury1qp.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664730573/celestial/inferno-bg_fum2w4.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664721249/celestial/Screen_Shot_2022-10-02_at_8.32.11_PM_ffg13z.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664731255/celestial/purgatory-gate_lkszj0.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664730722/celestial/purgatory-bg_gh4sby.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664725953/celestial/purg-poster_z5p7wz.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664730723/celestial/paradise-gate-top_jzek0x.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664730723/celestial/paradise-gate-bottom-2_lqattm.png",
  "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664725952/celestial/par-poster_tagxgy.png",
];

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const cacheImgs = async (images: string[]) => {
      setError(null);
      const promises = await images.map((src: string) => {
        return new Promise((res, rej) => {
          const img = new Image();
          img.src = src;
          // @ts-ignore
          img.onload = () => res();
          img.onerror = () => rej();
        });
      });

      await Promise.all(promises).catch((error) => setError(error));

      setIsLoading(false);
    };

    cacheImgs(images);
  }, []);

  return (
    <>
      <ProductsProvider>
        <CartProvider>
          {isLoading ? (
            <div
              style={{
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BarLoader />
            </div>
          ) : error ? (
            <div style={{ textAlign: "center", marginTop: "20vh" }}>
              Something went wrong. Please try again later
            </div>
          ) : (
            <AppRoutes />
          )}
        </CartProvider>
      </ProductsProvider>
    </>
  );
};
