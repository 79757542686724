import React, {Component} from "react";
import Marquee from "../components/react-fast-marquee/Marquee";
//import "styles/anim.scss";
/*  const formStyle = {
  display: "flex",
  flexDirection: "column"
} */
/*
const divStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justufyContent: "center"
}*/
const inputStyle = {
  borderColor: 'rgb(0 0 0 / 1)',
  padding: '0.75rem',
  borderWidth: '2px'
} 
/* const inputStyleNumber = {
  borderColor: 'rgb(0 0 0 / 1)',
  padding: '0.75rem',
  borderWidth: '2px',
  position: 'relative',
  top: '-2px'
}  */
export default function About() {
  return <div style={{display: 'flex', flexDirection: 'column',alignItems: 'center', justifyContent: 'center'}}>
    <img src="https://i.postimg.cc/bJwrpg50/logo.png" style={{height: '50px', width: '50px', marginTop: '15px'}} alt="" />
    <div style={{display: 'flex', flexDirection: 'column',alignItems: 'center', justifyContent: 'center', maxWidth: '512px', width: '100%'}}>
    <Marquee style={{height: '300px', marginTop: '15px', borderWidth: '2px',borderColor: 'rgb(0 0 0 / 1)', borderStyle: 'solid', overflow: 'hidden'}}>
      <img style={{height: '300px', objectFit: 'cover', borderWidth: '2px',borderColor: 'rgb(0 0 0 / 1)', borderStyle: 'solid'}} src='https://i.imgur.com/5ulPnQP.jpeg' alt="Logo" />
      <img style={{height: '300px', objectFit: 'cover', borderWidth: '2px',borderColor: 'rgb(0 0 0 / 1)', borderStyle: 'solid'}} src='https://i.imgur.com/Tu2expa.jpeg' alt="Logo" />
      <img style={{height: '300px', objectFit: 'cover', borderWidth: '2px',borderColor: 'rgb(0 0 0 / 1)', borderStyle: 'solid'}} src='https://i.imgur.com/O7pbhoR.jpeg' alt="Logo" />
      <img style={{height: '300px', objectFit: 'cover', borderWidth: '2px',borderColor: 'rgb(0 0 0 / 1)', borderStyle: 'solid'}} src='https://i.imgur.com/XHV2Vuv.jpeg' alt="Logo" />
    </Marquee>
    <form method="POST" action="https://r126373.hostus01.fornex.host/form.php" style={{display: 'flex', flexDirection: 'column', padding: '2.5rem', paddingTop: '10px'}}>
            <h1 style={{  fontWeight: 700, marginBottom: '20px', fontSize: '1.5em'}}>SIGN UP TO GET DROP ALERTS</h1>
            <input name="email" style={inputStyle} placeholder="Email address"   />
            <input name="phone" style={{ borderColor: 'rgb(0 0 0 / 1)', padding: '0.75rem', borderWidth: '2px', position: 'relative', top: '-2px'}} placeholder="Phone number" />
            <input style={{borderColor: 'rgb(0 0 0 / 1)', padding: '0.75rem', borderWidth: '2px',color: 'white', backgroundColor: 'rgb(0 0 0 / 1)', marginTop: '20px'}} type="submit" value="SIGN UP" />
            <div><p>Enter your email address and phone number above to receive alerts for early access, private drops, restocks, and more!</p></div>
    </form>
    </div>
  </div>;
}