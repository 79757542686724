import * as React from "react";
import s from "./Notification.module.sass";

const Notification = () => {
  return (
    <div className={s.Notification}>
      <div className={s.NotificationIcon}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.3">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.3002 34V9.68H27.2581H9.7423H5.7002V34H31.3002Z"
              fill="white"
            />
            <path
              d="M9.7423 9.68H5.7002V34H31.3002V9.68H27.2581M9.7423 9.68C9.7423 7.12004 12.437 2 18.5002 2C24.5634 2 27.2581 7.12004 27.2581 9.68M9.7423 9.68H27.2581"
              stroke="white"
              strokeWidth="2"
            />
          </g>
          <path
            d="M12.5 22.087L16.1364 26L24.5 17"
            stroke="white"
            strokeWidth="2"
          />
        </svg>
      </div>
      <span className={s.NotificationTitle}>Item added to bag</span>
    </div>
  );
};

export default Notification;
