import React from "react";
import clsx from "clsx";

import { useToggle } from "hooks/useToggle";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import s from "./ItemShowOptions.module.sass";

export interface ItemShowOptionsProps {
  name: string;
  showOptionName: boolean;
  options: Array<string>;
  onSelect: (name: string, value: string) => void;
  selected: string;
  index: number;
}

export const ItemShowOptions: React.FC<ItemShowOptionsProps> = ({
  name,
  showOptionName,
  options,
  onSelect,
  selected,
  index,
}) => {
  const showDropdown = useToggle();
  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

  const handleSelect = (value: string) => () => {
    showDropdown.unset();
    onSelect(name, value);
  };


  return (
    <>
      {showOptionName && (
        <div className={s.ItemShowOptionsTitle}>{capitalizedName}</div>
      )}
      <div className={s.ItemShowOptions}>
        <div
          onClick={showDropdown.toggle}
          className={s.ItemShowOptionsChildren}
        >
          <div className={s.ItemShowOptionsSelct}>{selected}</div>
          <ArrowIcon
            className={clsx(s.ItemShowOptionsSelctArrow, {
              [s.ItemShowOptionsSelctArrow_active]: showDropdown.value,
            })}
          />
        </div>
        <div
          className={clsx(s.ItemShowOptionsDropdown, {
            [s.ItemShowOptionsDropdown_show]: showDropdown.value,
          })}
        >
          {options.map((o, index) => (
            <div
              onClick={handleSelect(o)}
              className={s.ItemShowOptionsOption}
              key={`size-${index}`}
            >
              {o}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
