import * as React from "react";
import s from "./SocialLinks.module.sass";

type TSocialLinkProps = {
  link: string;
  img: string;
};

const SocialLink: React.FC<TSocialLinkProps> = ({ link, img }) => {
  return (
    <a className={s.SocialLink} href={link} target="_blank" rel="noreferrer">
      <img className={s.SocialLinkImg} src={img} alt="Social icon" />
    </a>
  );
};

export default SocialLink;
