import { api } from "api/api";
import { routesConfig } from "config/index";
import type { ProductModels } from "models/index";
import type { API } from "types";

export function getOne(id: string | undefined) {
  return api.get<API.Service.Response.FindOne<ProductModels.Data>>(
    routesConfig.productAPIRoutes.findOne(id)
  );
}

export async function fetchAll(params: any | null = null) {
  return await api.get<
    API.Service.Function<API.Service.Response.FindMany<ProductModels.Data>>
  >(routesConfig.productAPIRoutes.fetchAll(), params);
}
