import React, { useContext, useState, useEffect } from "react";
import { productService } from "services";
import useScript from "react-script-hook";

const ProductsContext = React.createContext<Partial<IProductsContext>>({});

export const useProducts = () => {
  return useContext(ProductsContext);
};

interface ProductProviderProps {
  children: React.ReactNode;
}

const ProductsProvider: React.FC<ProductProviderProps> = ({ children }) => {
  const [products, setProducts] = useState<any>(null);
  const [loading, error] = useScript({
    src: "https://app.ecwid.com/script.js?10960248",
  });

  const getProducts = async () => {
    try {
      const { data } = await productService.fetchAll();

      console.log(data);

      // @ts-ignore
      const items = data?.items?.map((item: any) => {
        if (item.enabled) {
          return {
            images: item?.media?.images?.map((i: any) => i.image800pxUrl),
            id: item?.id,
            name: item?.name,
            price: item?.price,
            options: item?.options?.map((opt: any) => ({
              name: opt.name,
              choices: opt?.choices?.map((choice: any) => choice.text),
            })),
            description: item?.description,
          };
        } else {
          return null;
        }
      });

      const products = items.filter((i: any) => i !== null);
      setProducts(products);
    } catch (error: any) {
      console.log(error.message);
    }
    // }
  };

  const getProductById = (id: number | string | undefined) => {
    return products.find((pr: any) => pr.id === id);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <ProductsContext.Provider value={{ products, getProductById }}>
      {products && children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;

interface IProductsContext {
  products: any[];
  getProductById: (id: number | string | undefined) => any;
}
