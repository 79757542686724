import React, { useState, Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { CSSTransition } from "react-transition-group";
import "styles/anim.scss";

import { ITEMS_PAGE_ROUTE } from "routes/routesNames";

import s from "styles/pages/PortalShow.module.sass";

const portals = [
  {
    id: 0,
    title: "INFERNO",
    portal:
      "https://res.cloudinary.com/dalyoko-dan-tk/video/upload/f_auto,q_80/v1662563167/celestial/portal_iqkedh.mp4",
    coverImg:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664731609/celestial/inferno-gate_ury1qp.png",
    bottomImg:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664730573/celestial/inferno-bg_fum2w4.png",
    poster:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664721249/celestial/Screen_Shot_2022-10-02_at_8.32.11_PM_ffg13z.png",
  },
  {
    id: 1,
    title: "PURGATORY",
    portal:
      "https://res.cloudinary.com/dalyoko-dan-tk/video/upload/f_auto,q_80/v1662563168/celestial/portal-2_luuqov.mp4",
    coverImg:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664731255/celestial/purgatory-gate_lkszj0.png",
    bottomImg:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664730722/celestial/purgatory-bg_gh4sby.png",
    poster:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664725953/celestial/purg-poster_z5p7wz.png",
  },
  {
    id: 2,
    title: "PARADISE",
    portal:
      "https://res.cloudinary.com/dalyoko-dan-tk/video/upload/f_auto,q_80/v1662563169/celestial/portal-3_u2bqob.mp4",
    coverImg:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664730723/celestial/paradise-gate-top_jzek0x.png",
    bottomImg:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664730723/celestial/paradise-gate-bottom-2_lqattm.png",
    poster:
      "https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1664725952/celestial/par-poster_tagxgy.png",
  },
];

const PortalShowPage = () => {
  const [currentPortal, setCurrentPortal] = useState(0);

  const onPrev = () => {
    if (currentPortal > 0) {
      setCurrentPortal((count) => count - 1);
    } else {
      setCurrentPortal(portals.length - 1);
    }
  };

  const onNext = () => {
    if (currentPortal < portals.length - 1) {
      setCurrentPortal((count) => count + 1);
    } else {
      setCurrentPortal(0);
    }
  };

  useEffect(() => {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const handleWindowResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const refVideo = useRef(null);

  useEffect(() => {
    if (refVideo.current) {
      // @ts-ignore
      refVideo.current.defaultMuted = true;
      // @ts-ignore
      refVideo.current.muted = true;
    }
  }, [refVideo.current]);

  return (
    <div className={s.MainWrapper}>
      <div
        className={
          currentPortal === 2 ? `${s.Wrapper} ${s.WrapWhite}` : s.Wrapper
        }
      >
        {portals.map((p, ind) => (
          <CSSTransition
            key={p.id}
            in={currentPortal === ind}
            timeout={0}
            classNames="portalVideo"
          >
            <Fragment key={`Portal ${p.id}`}>
              <div className={s.PortalWrapper}>
                <div
                  className={s.PortalCover}
                  style={{
                    display: currentPortal === ind ? "block" : "none",
                  }}
                >
                  <img
                    src={p.coverImg}
                    alt="portal cover"
                    className={s.PortalCoverImg}
                  />
                </div>

                <div
                  className={s.PortalUnder}
                  style={{
                    display: currentPortal === ind ? "block" : "none",
                  }}
                >
                  <img
                    src={p.bottomImg}
                    alt="portal bottom"
                    className={s.PortalCoverImg}
                  />
                </div>

                <video
                  className={
                    p.id === 0
                      ? clsx(s.PortalVideo, s.PortalVideoInferno)
                      : s.PortalVideo
                  }
                  loop
                  playsInline
                  muted
                  autoPlay
                  style={{
                    display: currentPortal === ind ? "block" : "none",
                  }}
                >
                  <source
                    className={s.PortalVideoSource}
                    src={p.portal}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div
                className={s.Navigation}
                style={{
                  display: currentPortal === ind ? "flex" : "none",
                }}
              >
                <div
                  onClick={onPrev}
                  id="arrow-prev"
                  className={clsx(s.Arrow, s.ArrowLeft)}
                />
                <Link
                  to={ITEMS_PAGE_ROUTE.replace(
                    ":portalId",
                    portals[currentPortal]?.id.toString()
                  )}
                  className={s.Title}
                >
                  {portals[currentPortal]?.title}
                </Link>
                <div
                  id="arrow-next"
                  onClick={onNext}
                  className={clsx(s.Arrow, s.ArrowRight)}
                />
              </div>
            </Fragment>
          </CSSTransition>
        ))}
      </div>
    </div>
  );
};
export default PortalShowPage;
