import React, { useCallback, useEffect, useRef, useState } from "react";

import s from "./CarouselMobile.module.sass";

import { CarouselProps } from "../Carousel";
import clsx from "clsx";

export interface CarouselMobileProps extends CarouselProps {
  currentImage: number;
  handleChangeCurrentImage: (value: number) => void;
}

export const CarouselMobile: React.FC<CarouselMobileProps> = ({
  images,
  handleChangeCurrentImage,
}) => {
  const onChangeCurrentImage = (value: number) => () => {
    handleChangeCurrentImage(value);
  };
  const [positions, setPositions] = useState<number[]>([]);

  const [currentPosition, setcurrentPosition] = useState<number>(0);

  const sliderRef = useRef(null);

  const handleSliderScroll = (e: any) => {
    // @ts-ignore
    const positions = getImagePositions(e.target);
    
    positions.forEach((_, ind) => {
      if (e.target.scrollLeft <= positions[ind] + 10 && e.target.scrollLeft >= positions[ind] - 10) {
        setcurrentPosition(ind)
      };
    })
  };

  const getImagePositions = useCallback((el: any) => {
    const positions: number[] = [];
    const width = el.clientWidth;
    images.forEach((i, ind) => {
      if (ind === 0) {
        positions.push(ind)
      } else {
        positions.push(ind * width)
      }
    })
    return positions
      // @ts-ignore
  }, [document.innerWidth])

  useEffect(() => {
    if (sliderRef.current) {
      setPositions(getImagePositions(sliderRef.current))
      
      // @ts-ignore
      sliderRef.current.addEventListener("scroll", handleSliderScroll);
    }
    return () => {
      // @ts-ignore
      sliderRef.current.removeEventListener("scroll", handleSliderScroll);
    };
      // @ts-ignore
  }, [sliderRef.current]);

  return (
    <div className={s.CarouselMobile}>
      <div className={s.CarouselMobileSlider}>
        <div ref={sliderRef} className={s.CarouselMobileSlidesContainer}>
          {images.map((i) => (
            <div key={i} className={s.CarouselMobileSlide}>
              <img src={i} alt="" className={s.CarouselMobileSlideImage} />
            </div>
          ))}
        </div>
      </div>

      <div className={s.CarouselMobileDots}>
        {images.map((i, index) => (
          <div
            onClick={onChangeCurrentImage(index)}
            className={clsx(s.CarouselMobileDot, {
              [s.CarouselMobileDot_active]: currentPosition === index,
            })}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};
