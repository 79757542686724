import React from "react";
import clsx from "clsx";

import { useToggle } from "hooks/useToggle";

import s from "./Collapse.module.sass";

export interface CollapseProps {
  title: string;
  dropdownData: Element | null;
  titleClassName?: string;
}

export const Collapse: React.FC<CollapseProps> = ({
  title,
  dropdownData,
  titleClassName,
}) => {
  const activeCollapse = useToggle();

  return (
    <div className={s.Collapse}>
      <div
        onClick={activeCollapse.toggle}
        className={clsx(s.CollapseTitle, titleClassName)}
      >
        {title}
        <span className={s.CollapsePlusIcon}>
          {!activeCollapse.value ? "+" : "-"}
        </span>
      </div>
      <div
        className={clsx(s.CollapseDropdown, {
          [s.CollapseDropdown_active]: activeCollapse.value,
        })}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: dropdownData ? dropdownData.outerHTML : "",
          }}
        />
      </div>
    </div>
  );
};
