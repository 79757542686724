import * as React from "react";
import { Link } from "react-router-dom";
import { ITEM_PAGE_SHOW_ROUTE } from "routes/routesNames";
import s from "styles/pages/Items.module.sass";
import clsx from "clsx";

type ProductItemProps = {
  id: string;
  name: string;
  image: string;
  isInSlider?: boolean;
};

const ProductItem: React.FC<ProductItemProps> = ({
  id,
  name,
  image,
  isInSlider,
}) => {
  return (
    <Link
      to={ITEM_PAGE_SHOW_ROUTE.replace(":id", id.toString()).replace(":portalId","1").replace(":title", name.toString())}
      className={isInSlider ? clsx(s.Item, s.ItemInSlider) : s.Item}
      key={id}
    >
      <div className={s.ItemNameWrapper}>
        <span className={s.ItemName}>{name}</span>
      </div>
      <div className={s.ItemImageWrapper}>
        <img src={image} alt={name} className={s.ItemImage} />
      </div>
    </Link>
  );
};

export default ProductItem;
