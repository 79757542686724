import React, { useRef } from "react";
import clsx from "clsx";

import s from "./CarouselPC.module.sass";

import { CarouselProps } from "../Carousel";

export interface CarouselPCProps extends CarouselProps {
  currentImage: number;
  handleChangeCurrentImage: (value: number) => void;
}

export const CarouselPC: React.FC<CarouselPCProps> = ({
  images,
  currentImage,
  slidesClassName,
  paginationClassName,
  handleChangeCurrentImage,
}) => {
  // const slideRef = useRef<HTMLDivElement>(null);

  const onChangeCurrentImage = (value: number) => () => {
    handleChangeCurrentImage(value);
      const image = document.querySelector(`#img-${value}`);
      image && image.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      <div className={clsx(s.CarouselPCPagination, paginationClassName)}>
        {images.map((i, index) => (
          <div
            onClick={onChangeCurrentImage(index)}
            className={s.CarouselPCPaginationItem}
            key={i}
          >
            <img
              src={i}
              alt=""
              className={clsx(s.CarouselPCPaginationImage, {
                [s.CarouselPCPaginationImage_active]: currentImage === index,
              })}
            />
          </div>
        ))}
      </div>
      <div className={clsx(s.CarouselPCSlides, slidesClassName)}>
        {images.map((i, index) => (
          <div id={`img-${index}`} className={s.CarouselPSlide} key={i}>
            <img src={i} alt="" className={s.CarouselPSlideImage} />
          </div>
        ))}
      </div>
    </>
  );
};
