export const INDEX_PAGE_ROUTE = "/";

export const ITEMS_PAGE_ROUTE = "/items/:portalId";

export const ITEM_PAGE_SHOW_ROUTE = `/store#!/:title/p/:id/category=:portalId`;

export const CART_PAGE_ROUTE = `/cart`;

export const CHECKOUT_ROUTE = "/store";

export const PORTAL_PAGE_ROUTE = `/portals`;