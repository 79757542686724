import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ReactComponent as BloodIcon } from "assets/icons/pages/itemShow/blood.svg";
import s from "styles/pages/ItemShow.module.sass";
import { Carousel } from "components/Carousel/Carousel";
import { Collapse } from "components/Collapse/Collapse";
import { ItemShowOptions } from "components/pages/itemShow/ItemShowOptions/ItemShowOptions";
import { Button } from "components/Button/Button";
import { useCart } from "hooks/useCart";
import { useProducts } from "hooks/useProducts";
import Notification from "components/Notification/Notification";
import { CART_PAGE_ROUTE } from "routes/routesNames";
import SocialLinks from "components/SocialLink/SocialLinks";

export interface ItemShowPageProps {}

export interface Options {
  [name: string]: string;
}

type description = {
  title: string;
  content: Element | null;
};

const ItemShowPage: React.FC<ItemShowPageProps> = () => {
  // const id = "445365810";
  const params = useParams();
  const name = params.name;
  const id = params.id && +params.id;
  const { updateCart, getCart, cart } = useCart();
  const { products, getProductById } = useProducts();
  const [isAdded, setAdded] = useState(false);
  const [item, setItem] = useState<any>(null);
  const [selectedOptions, setSelectedOptions] = useState<Options>({});
  const [error, setError] = useState<string | null>(null);
  const [description, setDescription] = useState<Array<description>>([]);

  const parseDescription = (description: string) => {
    const div = document.createElement("div");
    div.innerHTML = description;

    const descr = div.querySelector(".description");
    const shipping = div.querySelector(".shipping");
    const details = div.querySelector(".details");
    const size = div.querySelector(".size");

    setDescription([
      { title: "Item description", content: descr },
      { title: "Shipping and returns", content: shipping },
      { title: "Details & care", content: details },
      { title: "Size chart", content: size },
    ]);
  };

  useEffect(() => {
    const product = getProductById && getProductById(id);
    setItem(product);

    parseDescription(product.description);

    const initialSelectedOptions: Options = {};
    product &&
      product.options.forEach(
        (option: any) =>
          (initialSelectedOptions[
            option.name
          ] = `Select ${option.name.toLowerCase()}`)
      );
    setSelectedOptions(initialSelectedOptions);
  }, [products]);

  const handleOptionSelection = (name: string, value: string): void => {
    setError(null);
    const renewedOptions: Options = { ...selectedOptions };
    renewedOptions[name] = value;

    setSelectedOptions(renewedOptions);
  };

  const checkOptionsSelection = (selectedOptions: Options): boolean => {
    for (let option of Object.values(selectedOptions)) {
      if (option.includes("Select")) {
        return false;
      }
    }
    return true;
  };

  const handleAddtoBag = () => {
    if (!checkOptionsSelection(selectedOptions)) {
      setError("Please select all options");
      return;
    }

    setAdded(true);
    updateCart &&
      updateCart({
        id: item.id,
        name: item.name,
        size: "",
        options: selectedOptions,
        price: item.price,
        image: item.images[0],
        count: 1,
      });
    window.Ecwid.Cart.addProduct({
      id: item.id,
      quantity: 1,
      options: selectedOptions,
    });
    setTimeout(() => {
      setAdded(false);
    }, 1500);
  };

  return (
    <>
      {item && (
        <>
          <div className={s.ItemShowContent}>
            <Helmet>
              <title>{item.name} - Celestial</title>
            </Helmet>
            <Carousel
              // images={item?.images.slice(1)}
              images={item?.images}
              paginationClassName={s.ItemShowCarouselPagination}
              slidesClassName={s.ItemShowCarouselSlides}
            />
            <div className={s.ItemShowShortData}>
              <div className={s.ItemShowLogo}>{item?.name}</div>
              <div className={s.ItemShowPriceWrapper}>
                $<span className={s.ItemShowPrice}>{item?.price}</span>
              </div>
              <div className={s.ItemShowWarning}>
                9{" "}
                <span className={s.ItemShowWarningSizes}>
                  Sizes
                  <BloodIcon className={s.ItemShowWarningIcon} />
                </span>{" "}
                Circles of Hell
              </div>
              {selectedOptions &&
                item?.options &&
                item?.options.map((option: any, index: number) => (
                  <ItemShowOptions
                    key={option?.name}
                    name={option?.name}
                    showOptionName={option?.name === "Size" ? false : true}
                    options={option?.choices}
                    onSelect={handleOptionSelection}
                    selected={selectedOptions[option?.name]}
                    index={index}
                  />
                ))}

              {cart && cart.length > 0 && (
                <Link
                  to={CART_PAGE_ROUTE}
                  onClick={() => getCart && getCart()}
                  className={s.ItemShowBuyNow}
                >
                  <span>Buy now</span>
                </Link>
              )}

              <Button
                size="lg"
                className={s.ItemShowButton}
                onClick={handleAddtoBag}
              >
                Add to bag
              </Button>

              {isAdded && <Notification />}
              {error && <div className={s.ItemShowSizeError}>{error}</div>}
            </div>
            <div className={s.ItemShowData}>
              {description &&
                description.map((d) => (
                  <Collapse
                    key={d.title + d.content}
                    title={d.title}
                    dropdownData={d.content}
                    titleClassName={s.ItemShowOptionsTitle}
                  />
                ))}
              <SocialLinks />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ItemShowPage;
