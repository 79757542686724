import { useEffect } from "react";

export const useDisableOverflow = (isDisabled?: boolean) => {
  useEffect(() => {
    document.body.style.overflowY = isDisabled ? "hidden" : "";

    return () => {
      document.body.style.overflowY = "";
    };
  }, [isDisabled]);
};
