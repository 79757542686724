import * as React from "react";
import telegramIcon from "assets/images/telegram-icon.png";
import tiktokIcon from "assets/images/tiktok-icon.png";
import instaIcon from "assets/images/insta-icon.png";
import SocialLink from "./SocialLink";
import s from "./SocialLinks.module.sass";

const links = [
  {
    link: "https://instagram.com/celestial_japan?igshid=YmMyMTA2M2Y=",
    img: instaIcon,
  },
  {
    link: "https://www.tiktok.com/@celestial_japan?_t=8XlI3u8CK7V&_r=1",
    img: tiktokIcon,
  },
  {
    link: "https://t.me/celestial_jp",
    img: telegramIcon,
  },
];

const SocialLinks = () => {
  return (
    <div className={s.SocialLinksContainer}>
      {links.map((l) => (
        <SocialLink key={l.link} {...l} />
      ))}
    </div>
  );
};

export default SocialLinks;
