import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import { useCart } from "hooks/useCart";
import { useLocation } from "react-router-dom";

function Checkout() {
  const location = useLocation();
  const storeDiv = useRef(null);
  const { getCart } = useCart();

  window.ecwid_script_defer = true;
  window.ecwid_dynamic_widgets = true;

  function ecwidDestroy() {
    if (window.Ecwid && window.Ecwid.destroy) {
      window.Ecwid.destroy();
      window.ecwid_onBodyDone();
    }
    window._xnext_initialization_scripts = [
      {
        widgetType: "ProductBrowser",
        id: "my-store-10960248",
        arg: ["id=productBrowser"],
      },
    ];
    window.ec = window.ec || {};
    window.ec.storefront = window.ec.storefront || {};
    window.ec.storefront.enable_navigation = true;

    window.Ecwid.OnCartChanged.add(function (cart) {
      getCart && getCart();
    });
  }

  function goToCart() {
    setTimeout(() => {
      window.Ecwid.openPage && window.Ecwid.openPage("cart");
    }, 1000);
  }

  useEffect(() => {
    ecwidDestroy();
  }, []);

  return (
    <>
      <Helmet>
        <title>Celestial</title>
      </Helmet>
      <div id="my-store-10960248" ref={storeDiv}></div>
      {!location.hash && goToCart()}
    </>
  );
}

export default Checkout;
