import axios from "axios";
import { apiConfig } from "config/index";

const api = axios.create({
	baseURL: apiConfig.serverURI,
	headers: {
		'Content-Type': 'application/json',
		'charset': 'utf-8',
	},
	params: {
		token: apiConfig.publicToken
	}
});

api.interceptors.request.use((req) => {
	return {
		...req,
		baseURL: apiConfig.serverURI,
	};
});

export { api };