import { Options } from "pages/items/[portalId]/[id]";
import React, { useContext, useState, useEffect } from "react";
import { useProducts } from "./useProducts";

const CartContext = React.createContext<Partial<ICartContext>>({});

export const useCart = () => {
  return useContext(CartContext);
};

interface CartProviderProps {
  children: React.ReactNode;
}

const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, setCart] = useState<ICartItem[]>([]);
  const { getProductById } = useProducts();

  const deleteCartItem = (id: number, options: Options): void => {
    const indexToDelete = cart.findIndex(
      (item) =>
        item.id === id &&
        JSON.stringify(item.options) === JSON.stringify(options)
    );
    cart.splice(indexToDelete, 1);
    setCart([...cart]);
  };

  const updateCart = (value: ICartItem) => {
    const newCart: ICartItem[] = [...cart];

    const index = newCart.findIndex(
      (item) =>
        item.id === value.id &&
        JSON.stringify(item.options) === JSON.stringify(value.options)
    );
    if (index === -1) {
      newCart.push(value);
    } else {
      const newCartItem = newCart[index];
      if (newCartItem && newCartItem.count) {
        newCartItem.count++;
      }
    }
    setCart(newCart);
  };

  const updateItemCount = (
    id: number,
    updateType: updateType,
    options: Options
  ): void => {
    const newCart: ICartItem[] = [...cart];
    const index = newCart.findIndex(
      (item) =>
        item.id === id &&
        JSON.stringify(item.options) === JSON.stringify(options)
    );
    if (updateType === "plus") {
      newCart[index].count++;
    } else {
      newCart[index].count--;
    }
    setCart(newCart);
  };

  function getCart() {
    window.Ecwid.OnAPILoaded.add(function () {
      window.Ecwid.Cart.get((cart: any) => {
        const newCart = cart.items.map((item: any) => ({
          id: item.product.id,
          name: item.product.name,
          size: item.options.size,
          options: item.options,
          price: item.product.price,
          count: item.quantity,
          image: getProductById && getProductById(item.product.id).images[0],
        }));

        setCart(newCart);
      });
    });
  }

  useEffect(() => {
    // getCart();
  }, []);

  return (
    <CartContext.Provider
      value={{ cart, updateCart, getCart, updateItemCount, deleteCartItem }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;

export interface ICartItem {
  id: number;
  name: string;
  size: string;
  options: Options;
  price: number;
  image: string;
  count: number;
}

interface ICartContext {
  cart: ICartItem[];
  getCart: () => void;
  updateCart: (value: ICartItem) => void;
  updateItemCount: (id: number, updateType: updateType, size: Options) => void;
  deleteCartItem: (id: number, options: Options) => void;
}

type updateType = "plus" | "minus";
