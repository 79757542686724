import React, { useState } from "react";

import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import s from "./CartItem.module.sass";

import { ICartItem, useCart } from "hooks/useCart";
import { Button } from "components/Button/Button";
import { Link } from "react-router-dom";

export interface CartItemProps extends ICartItem {
  index: number;
  totalPrice: number;
  onChangeTotalPrice: (value: number) => void;
}

export const CartItem: React.FC<CartItemProps> = ({
  id,
  name,
  image,
  count,
  options,
  price,
  totalPrice,
  onChangeTotalPrice,
  index,
}) => {
  const { deleteCartItem, updateItemCount } = useCart();
  const [quantity, setQuantity] = useState<number>(count);

  const handleIncrementQuantity = () => {
    setQuantity((prevState) => prevState + 1);
    updateItemCount && updateItemCount(id, "plus", options);
    onChangeTotalPrice(totalPrice + price);
  };

  const handleDecrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevState) => prevState - 1);
      updateItemCount && updateItemCount(id, "minus", options);
      onChangeTotalPrice(totalPrice - price);
    }
  };

  const handleItemDelete = () => {
    deleteCartItem && deleteCartItem(id, options);
    window.Ecwid.Cart.removeProduct(index);
  };

  return (
    <div className={s.CartItem}>
      <div>
        <div className={s.CartItemImageWrapper}>
          <Link to={`/items/${id}`}>
            <img src={image} alt="" className={s.CartItemImage} />
          </Link>
        </div>
      </div>
      <div className={s.CartItemContent}>
        <div className={s.CartItemNameDelete}>
          <div className={s.CartItemBrand}>
            <Link to={`/items/${id}`}>{name}</Link>
          </div>
          <div className={s.CartItemClose}>
            <CloseIcon
              className={s.CartItemCloseIcon}
              onClick={handleItemDelete}
            />
          </div>
        </div>
        {Object.keys(options).map((option) => (
          <div key={option} className={s.CartItemSize}>{`${option}: ${options[option]}`}</div>
        ))}
        <div className={s.CartItemPriceWrapper}>
          <Button variant="secondary" className={s.CartItemquantityButton}>
            <MinusIcon onClick={handleDecrementQuantity} />
            <span className={s.CartItemquantity}>{quantity}</span>
            <PlusIcon onClick={handleIncrementQuantity} />
          </Button>
          <div className={s.CartItemPrice}>${quantity && price * quantity}</div>
        </div>
      </div>
    </div>
  );
};
