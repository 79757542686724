import { serverURI } from "./api.config";

export const productAPIRoutes = {
	findOne: (id: string | undefined) => `${serverURI}/products/${id}`,
	fetchAll: () => `${serverURI}/products`
};

export const productBrowserRoutes = {
	findOne: (to: string | number = ":id") => `/product/${to}`,
};
