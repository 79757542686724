import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { useDisableOverflow } from "hooks/useDisableOverflow";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import s from "styles/pages/Cart.module.sass";

import { CartItem } from "components/pages/cart/CartItem/CartItem";
import { Button } from "components/Button/Button";
import { Logo } from "components/Logo/Logo";
import clsx from "clsx";
import { ICartItem, useCart } from "hooks/useCart";
import { Link } from "react-router-dom";

export interface CartPageProps {
  isBackground?: any;
}

declare global {
  interface Window {
    _xnext_initialization_scripts: any[];
    Ecwid: any;
  }
}

const CartPage: React.FC<CartPageProps> = ({ isBackground }) => {
  const { cart } = useCart();
  
  useDisableOverflow(true);

  function countTotalPrice(cart: ICartItem[] | undefined) {
    let totalPrice = 0;
    if (cart && cart.length > 0) {
      totalPrice = cart.reduce((a: number, b: ICartItem, ind: number) => {
        return a + b.price * b.count;
      }, 0);
    }
    return totalPrice;
  }
  const initialTotalPrice = countTotalPrice(cart);
  const [totalPrice, setTotalPrice] = useState<number>(initialTotalPrice);

  const handleChangeTotalPrice = (value: number) => {
    setTotalPrice(value);
  };

  const goBack = () => {
    window.history.back();
  };

  const updateEcwidCart = () => {
    window.Ecwid.Cart.clear();
    cart &&
      cart.forEach((item) =>
        window.Ecwid.Cart.addProduct({
          id: item.id,
          quantity: item.count,
          options: item.options,
        })
      );
  };

  return (
    <div className={s.CartPage}>
      <Helmet>
        <title>Cart - Celestial</title>
      </Helmet>
      <div
        className={clsx(s.CartPageBackground, {
          [s.CartPageBackground_dark]: !isBackground,
        })}
      >
        <Logo className={s.CartPageLogo} />
      </div>
      <div className={s.CartPageCart}>
        <div className={s.CartPageCartHeader}>
          <div className={s.CartPageTitle}>Shopping Cart</div>
          <CloseIcon onClick={goBack} className={s.CartPageCloseIcon} />
        </div>
        <div className={s.CartPageItems}>
          {cart &&
            cart.length > 0 &&
            cart.map((i, ind) => (
              <CartItem
                {...i}
                index={ind}
                totalPrice={totalPrice}
                onChangeTotalPrice={handleChangeTotalPrice}
                key={i.id + " " + JSON.stringify(i.options)}
              />
            ))}
        </div>
        <div className={s.CartPageTotalPrice}>
          <span>Subtotal:</span>
          <span>${cart && cart.length === 0 ? 0 : totalPrice}</span>
        </div>
        {cart && cart.length !== 0 && (
          <Link to="/store#!/~/cart">
            <Button
              size="lg"
              className={s.CartPageCheckOut}
              onClick={() => {
                updateEcwidCart();
              }}
            >
              Check Out
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};
export default CartPage;
