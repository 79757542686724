import React from "react";
import clsx from "clsx";

import s from "./Button.module.sass";
import { useToggle } from "hooks/useToggle";

export interface ButtonProps {
  variant?: "primary" | "secondary" | "outlined";
  size?: "sm" | "md" | "lg";
  htmlType?: "button" | "submit" | "reset";
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = "primary",
  size = "md",
  htmlType = "button",
  className,
  onClick,
}) => {
  const active = useToggle();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    active.toggle();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      type={htmlType}
      onClick={handleClick}
      className={clsx(
        s.Button,
        s[`Button_${size}`],
        s[`Button_${variant}`],
        { [s[`Button_${variant}_active`]]: active.value },
        className
      )}
    >
      {children}
    </button>
  );
};
