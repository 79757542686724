import React, { useCallback, useEffect, useRef, useState } from "react";
import s from "./BubbleSlider.module.sass";
import clsx from "clsx";
import ProductItem from "components/ProductItem/ProductItem";
import Soon from "components/Soon/Soon";
import arrowStyle from "styles/pages/PortalShow.module.sass";

export interface BubbleSliderProps {
  products: any[];
}

export const BubbleSlider: React.FC<BubbleSliderProps> = ({ products }) => {
  const [positions, setPositions] = useState<number[]>([]);
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const sliderRef = useRef(null);
  const slidesRefs = useRef(products.map((_) => React.createRef()));

  const handleSliderScroll = (e: any) => {
    const positions = getPositions(e.target);
    setPositions(positions);
    positions.forEach((_, ind) => {
      if (
        e.target.scrollLeft <= positions[ind] + 70 &&
        e.target.scrollLeft >= positions[ind] - 70
      ) {
        setCurrentPosition(positions[ind]);
        setCurrentIndex(ind);
      }
    });
  };

  const getPositions = useCallback(
    (el: any) => {
      const positions: number[] = [];
      const width = el.clientWidth;
      products.forEach((i, ind) => {
        if (ind === 0) {
          positions.push(ind);
        } else {
          positions.push(ind * width);
        }
      });
      return positions;
    },
    // @ts-ignore
    [document?.innerWidth]
  );

  useEffect(() => {
    if (sliderRef.current) {
      // setPositions(getPositions(sliderRef.current));

      // @ts-ignore
      sliderRef.current.addEventListener("scroll", handleSliderScroll);
    }
    return () => {
      // @ts-ignore
      sliderRef.current.removeEventListener("scroll", handleSliderScroll);
    };
    // @ts-ignore
  }, [sliderRef.current]);

  const handleChangePosition = (currentIndex: number, isNext: boolean) => {
    if (isNext) {
      if (currentIndex === products.length - 1) {
        setCurrentIndex(0);
        // @ts-ignore
        sliderRef.current.scrollLeft = positions[0];
      } else {
        setCurrentIndex((prevState) => prevState + 1);
        // @ts-ignore
        sliderRef.current.scrollLeft = positions[currentIndex + 1];
      }
    } else {
      if (currentIndex === 0) {
        setCurrentIndex(products.length - 1);
        // @ts-ignore
        sliderRef.current.scrollLeft = positions[products.length - 1];
      } else {
        setCurrentIndex((prevState) => prevState - 1);
        // @ts-ignore
        sliderRef.current.scrollLeft = positions[currentIndex - 1];
      }
    }
  };

  return (
    <div className={s.BubbleSlider}>
      <div className={s.BubbleSliderSlider}>
        <div ref={sliderRef} className={s.BubbleSliderSlidesContainer}>
          {products &&
            products.map((p, ind) =>
              p ? (
                <div
                  // @ts-ignore
                  ref={slidesRefs.current[ind]}
                  className={s.BubbleSliderSlide}
                  key={p.id}
                >
                  <ProductItem
                    isInSlider
                    id={p.id}
                    name={p.name}
                    image={p.images[0]}
                  />
                </div>
              ) : (
                <div
                  // @ts-ignore
                  ref={slidesRefs.current[ind]}
                  className={s.BubbleSliderSlide}
                  key={ind}
                >
                  <Soon isInSlider />
                </div>
              )
            )}
        </div>
        <div
          onClick={
            currentIndex === 0
              ? undefined
              : () => handleChangePosition(currentIndex, false)
          }
          id="arrow-prev"
          className={clsx(s.Arrow, s.ArrowLeft)}
          style={{ opacity: currentIndex === 0 ? "60%" : "100%" }}
        />

        <div
          id="arrow-next"
          onClick={
            currentIndex === products.length - 1
              ? undefined
              : () => handleChangePosition(currentIndex, true)
          }
          className={clsx(s.Arrow, s.ArrowRight)}
          style={{
            opacity: currentIndex === products.length - 1 ? "60%" : "100%",
          }}
        />
      </div>
    </div>
  );
};
