import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import { CART_PAGE_ROUTE } from "routes/routesNames";

import { ReactComponent as CartIcon } from "assets/icons/cart.svg";

import s from "./MainLayout.module.sass";

import { DefaultLayout } from "layouts/Default/DefaultLayout";
import { Navbar } from "components/Navbar/Navbar";
import { useCart } from "hooks/useCart";

export interface MainLayoutProps {}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { cart, getCart } = useCart();
  const initialCartSize = cart && cart.length;

  const [cartSize, setCartSize] = useState<number | undefined>(initialCartSize);

  useEffect(() => {
    const newCartSize = cart && cart.length;
    setCartSize(newCartSize);
  }, [cart]);

  return (
    <DefaultLayout>
      <Navbar
        rightContent={
          <Link
            to={CART_PAGE_ROUTE}
            state={{ background: location }}
            className={clsx(s.MainLayoutNavbarCart, {
              [s.MainLayoutNavbarCart_active]: false,
            })}
            onClick={() => getCart && getCart()}
          >
            <span className={s.MainLayoutNavbarCartCount}>{cartSize}</span>
            <CartIcon className={s.MainLayoutNavbarCartIcon} />
          </Link>
        }
      />

      {children}
    </DefaultLayout>
  );
};
