import React, { useState } from "react";

import s from "./Carousel.module.sass";

import { CarouselMobile } from "./CarouselMobile/CarouselMobile";
import { CarouselPC } from "./CarouselPC/CarouselPC";

export interface CarouselProps {
  images: string[];
  slidesClassName?: string;
  paginationClassName?: string;
}

export const Carousel: React.FC<CarouselProps> = ({
  images,
  slidesClassName,
  paginationClassName,
}) => {
  const isMobile = window.innerWidth <= 768;
  const [currentImage, setCurrentImage] = useState(0);

  const handleChangeCurrentImage = (value: number) => {
    setCurrentImage(value);
  };

  return !isMobile ? (
    <CarouselPC
      images={images}
      currentImage={currentImage}
      slidesClassName={slidesClassName}
      paginationClassName={paginationClassName}
      handleChangeCurrentImage={handleChangeCurrentImage}
    />
  ) : (
    <CarouselMobile
      images={images}
      currentImage={currentImage}
      slidesClassName={slidesClassName}
      paginationClassName={paginationClassName}
      handleChangeCurrentImage={handleChangeCurrentImage}
    />
  );
};
