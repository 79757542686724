import React from "react";

import s from "./Navbar.module.sass";

import { Logo } from "components/Logo/Logo";
import { useNavigate } from "react-router-dom";

export interface NavbarProps {
  rightContent: React.ReactNode;
}

export const Navbar: React.FC<NavbarProps> = ({ rightContent }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const renderContentMobile = () => (
    <div className={s.Navbar_mobile}>
      <div className={s.NavbarLogoWrapper}>
        <img
          src="https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1665045697/celestial/arrow_mnsadn.png"
          alt="arrow"
          className={s.NavbarArrow}
          onClick={handleGoBack}
        />
        <Logo className={s.NavbarLogo} />
      </div>
      {rightContent}
    </div>
  );

  const renderContentDesktop = () => (
    <div className={s.Navbar_desktop}>
      <img
        src="https://res.cloudinary.com/dalyoko-dan-tk/image/upload/f_auto,q_80/v1665045697/celestial/arrow_mnsadn.png"
        alt="arrow"
        className={s.NavbarArrow}
        onClick={handleGoBack}
      />

      <Logo className={s.NavbarLogo} />
      {rightContent}
    </div>
  );

  return (
    <div className={s.Navbar}>
      {renderContentMobile()}
      {renderContentDesktop()}
    </div>
  );
};
