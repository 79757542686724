import { Routes, RouteType } from "hocs/Routes";
import {
  INDEX_PAGE_ROUTE,
  ITEM_PAGE_SHOW_ROUTE,
  ITEMS_PAGE_ROUTE,
  CHECKOUT_ROUTE,
  PORTAL_PAGE_ROUTE,
} from "./routesNames";

import Home from "pages/home";
import ItemShowPage from "pages/items/[portalId]/[id]";
import { MainLayout } from "layouts/Main/MainLayout";
import ItemsPage from "pages/items/[portalId]";
import Checkout from "pages/checkout";
import About from "pages/form";
import PortalShowPage from "pages/portal/[title]";

const routes: RouteType[] = [
  {
    path: INDEX_PAGE_ROUTE,
    element: <PortalShowPage />,
  },
  /* {
    path: INDEX_PAGE_ROUTE,
    element: <Home />,
  }, */
  {
    path: ITEMS_PAGE_ROUTE,
    element: <ItemsPage />,
    layout: MainLayout,
  },
  {
    path: ITEM_PAGE_SHOW_ROUTE,
    element: <ItemShowPage />,
    layout: MainLayout,
  },
  {
    path: CHECKOUT_ROUTE,
    element: <Checkout />,
    layout: MainLayout,
  },
  /* {
    path: PORTAL_PAGE_ROUTE,
    element: <PortalShowPage />,
  }, */
  {
    path: `/form`,
    element: <About />,
  },
];

export const AppRoutes = Routes(routes);
