import * as React from "react";
import s from "styles/pages/Items.module.sass";
import clsx from "clsx";

const Soon = ({ isInSlider }: { isInSlider?: boolean }) => {
  return (
    <>
      <div
        className={
          isInSlider
            ? clsx(s.Item, s.ItemInSlider, s.ItemSoon)
            : clsx(s.Item, s.ItemSoon)
        }
      >
        <div className={s.ItemNameWrapper}>
          <span className={s.ItemName}>soon</span>
          <span className={s.ItemName}>soon</span>
          <span className={s.ItemName}>soon</span>
        </div>
        <div className={s.ItemSoonWrapper}>
          <span className={s.ItemSoonTitle}>soon</span>
        </div>
      </div>
    </>
  );
};

export default Soon;
